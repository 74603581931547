import { useState } from "react";
import { useTranslation } from 'react-i18next';
import Terminal from "./Terminal";
import "./MySkills.css";

function MySkills() {
  const [currentCommand, setCurrentCommand] = useState({
    count: 0,
    commandState: "",
  });

  const { t } = useTranslation();

  const commands = [
    {
      shell: "Apps",
      button: t('appsButton'),
    },
    {
      shell: "Programming",
      button: t('programmingButton'),
    },
    {
      shell: "Frameworks",
      button: t('frameworksButton'),
    },
    {
      shell: "Version",
      button: t('versionButton'),
    },
    {
      shell: "management",
      button: t('managementButton'),
    },
  ];

  const leftCommands = commands.slice(0, 3);
  const rightCommands = commands.slice(3);

  return (
    <div className="my-skills-container">
      <div className="button-container-left">
        {leftCommands.map((command, i) => (
          <button
            key={"left-"+i}
            className={`neubrutalism-button button-${i}`}
            onClick={() =>
              setCurrentCommand({
                count: currentCommand.count + 1,
                commandState: command.shell,
              })
            }
          >
            {command.button}
          </button>
        ))}
      </div>

      <div className="terminal-container">
        <Terminal currentCommand={currentCommand} />
      </div>

      <div className="button-container-right">
        {rightCommands.map((command, i) => (
          <button
            key={"right-"+i}
            className={`neubrutalism-button button-${i + leftCommands.length}`}
            onClick={() =>
              setCurrentCommand({
                count: currentCommand.count + 1,
                commandState: command.shell,
              })
            }
          >
            {command.button}
          </button>
        ))}
      </div>
    </div>
  );
}

export default MySkills;
