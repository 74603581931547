import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Element } from "react-scroll";
import NavBar from "./components/navbar/NavBar.jsx";
import Home from "./components/home/Home.jsx";
import WorkExperience from "./components/workexperience/WorkExperience.jsx";
import MySkills from "./components/skills/MySkills.jsx";
import Contact from "./components/contact/Contact.jsx";
import Footer from "./components/footer/Footer.jsx";
import "./App.css";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  return (
    <Router>
      <div className="page-bg-style">
        <NavBar />
        <Element name="home">
          <Home />
        </Element>
        <Element name="work">
          <WorkExperience />
        </Element>
        <Element name="skills">
          <MySkills />
        </Element>
        <Element name="contact">
          <Contact />
        </Element>
        <Footer />
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
            fontSize: "14px",
            display: "flex",
            gap: "10px", // Add space between buttons
          }}
        >
          <button
            onClick={() => changeLanguage("en")}
            style={{
              background: "none",
              border: "none",
              color: i18n.language === "en" ? "yellow" : "white", // Highlight the active language
              cursor: "pointer",
            }}
          >
            English
          </button>
          <button
            onClick={() => changeLanguage("es")}
            style={{
              background: "none",
              border: "none",
              color: i18n.language === "es" ? "yellow" : "white", // Highlight the active language
              cursor: "pointer",
            }}
          >
            Español
          </button>
        </div>
      </div>
    </Router>
  );
}

export default App;
