import React, { useState, useRef, useEffect, useCallback } from "react";
import "./MySkills.css";
import { useTranslation } from "react-i18next";

const validCommands = [
  'help', '-h', 'apps', 'programming', 
  'frameworks', 'version', 'management', 
  'education', 'clear'
];

const Terminal = ({ currentCommand }) => {
  const { t } = useTranslation();
  const [input, setInput] = useState(t("inputTerminal"));
  const [output, setOutput] = useState([]);
  const [showProTip, setShowProTip] = useState(true);
  const [commandHistory, setCommandHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion] = useState(0);

  // Refs
  const typingInterval = useRef(null);
  const commandIndex = useRef(0);
  const currentCommandRef = useRef(currentCommand);
  const outputRef = useRef(null);
  const inputRef = useRef(null);

  // Update current command ref
  useEffect(() => {
    console.log(currentCommand, 'currentCommand')
    currentCommandRef.current = currentCommand;
  }, [currentCommand]);

  // Command suggestions
  useEffect(() => {
    if (input.length > 0 && input !== t("inputTerminal")) {
      const matches = validCommands.filter((cmd) =>
        cmd.startsWith(input.toLowerCase())
      );
      setSuggestions(matches);
    } else {
      setSuggestions([]);
    }
  }, [input, t]);

  // Command execution logic
  const executeCommand = useCallback(
    (command) => {
      let result;
      const cleanCommand = command.trim().toLowerCase();

      switch (cleanCommand) {
        case "-h":
        case "help":
          result = t("helpMessage");
          break;
        case "apps":
          result = JSON.stringify(
            {
              "Applications & Tools": [
                "VSCode: 4 years",
                "Git: 4 years",
                "GitHub: 4 years",
                "Bitbucket: 2 years",
                "Slack: 2 years",
                "Notion: 2 years",
                "Teams: 1 year",
                "Marketing Cloud: 1 year",
              ],
            },
            null,
            2
          );
          break;
        case "programming":
          result = JSON.stringify(
            {
              "Programming Languages": [
                // eslint-disable-next-line no-script-url
                "JavaScript: 4 years",
                "TypeScript: 2 years",
                "Java: 1 year",
                "Python: 1 year",
                "HTML5: 4 years",
                "CSS: 4 years",
              ],
            },
            null,
            2
          );
          break;
        case "frameworks":
          result = JSON.stringify(
            {
              Frameworks: {
                FrontEnd: [
                  "React: 3 years",
                  "Next.js: 3 years",
                  "Vue.js: 1.5 years",
                ],
                BackEnd: ["Koa.js: 1.5 years", "Sequelize: 1.5 years"],
                "CSS Framework": ["Tailwind: 1 year"],
              },
              Libraries: {
                "UI Libraries": ["Ant Design (React/Vue): 1.5 years"],
                "State Management": ["Redux: 1 year", "Pinia: 1 year"],
              },
            },
            null,
            2
          );
          break;
        case "version":
          result = JSON.stringify(
            {
              VersionControl: [
                "Git: 4 years",
                "GitHub: 4 years",
                "Bitbucket: 2 years",
              ],
              Deployment: [
                "Docker: 2 years",
                "Bitbucket Pipelines: 2 years",
                "GitHub Actions: 1 year",
              ],
            },
            null,
            2
          );
          break;
        case "management":
          result = JSON.stringify(
            {
              "Management Methodologies": {
                Kanban: "1 year",
                Scrum: "Basic knowledge",
              },
            },
            null,
            2
          );
          break;
        case "education":
          result = JSON.stringify(
            {
              Education: {
                Degree:
                  "Bachelor of Science: Information and Communications Technology",
                Institution:
                  "Instituto Tecnológico Nacional campus San Juan del Río",
                Dates: "2015 - 2020",
                "Professional ID": "14579325",
              },
            },
            null,
            2
          );
          break;
        case "clear":
          setOutput([]);
          return;
        default:
          const closestCommands = findClosestCommands(cleanCommand);
          result = (
            <span className="error-message">
              {t("unknownCommandMessage")}
              {closestCommands.length > 0 && (
                <>
                  <br />
                  {t("didYouMean")}
                  <ul className="suggestion-list">
                    {closestCommands.map((cmd, i) => (
                      <li key={i} onClick={() => runCommand(cmd)}>
                        {cmd}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </span>
          );
      }

      setOutput((prev) => [...prev, { command, result }]);
      setCommandHistory((prev) => [...prev, command]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  // Find closest matching commands
  const findClosestCommands = (inputCommand) => {
    return validCommands
      .map((cmd) => ({
        cmd,
        score: levenshteinDistance(inputCommand, cmd),
      }))
      .filter(({ score }) => score <= 2)
      .sort((a, b) => a.score - b.score)
      .map(({ cmd }) => cmd)
      .slice(0, 3);
  };

  // Levenshtein distance algorithm
  const levenshteinDistance = (a, b) => {
    const matrix = [];
    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }
    for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }
    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        const cost = b.charAt(i - 1) === a.charAt(j - 1) ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j - 1] + cost
        );
      }
    }
    return matrix[b.length][a.length];
  };

  // Unified command runner
  const runCommand = useCallback(
    (command) => {
      executeCommand(command);
      setInput("");
      setSuggestions([]);
      setHistoryIndex(-1);
    },
    [executeCommand]
  );

  // Handle keyboard events
  const handleKeyPress = (e) => {
    switch (e.key) {
      case "Enter":
        runCommand(input);
        break;
      case "Tab":
        e.preventDefault();
        if (suggestions.length > 0) {
          setInput(suggestions[selectedSuggestion]);
          setSuggestions([]);
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        if (commandHistory.length > 0) {
          const newIndex =
            historyIndex < commandHistory.length - 1
              ? historyIndex + 1
              : historyIndex;
          setHistoryIndex(newIndex);
          setInput(commandHistory[commandHistory.length - 1 - newIndex]);
        }
        break;
      case "ArrowDown":
        e.preventDefault();
        if (historyIndex > 0) {
          const newIndex = historyIndex - 1;
          setHistoryIndex(newIndex);
          setInput(commandHistory[commandHistory.length - 1 - newIndex]);
        } else if (historyIndex === 0) {
          setHistoryIndex(-1);
          setInput("");
        }
        break;
      case "ArrowLeft":
      case "ArrowRight":
        // Allow default cursor movement
        break;
      default:
      // Handle other keys normally
    }
  };

  // Update current command ref
  useEffect(() => {
    currentCommandRef.current = currentCommand;
  }, [currentCommand]);

  // Typing animation effect
useEffect(() => {
  const handleTyping = () => {
    if (!currentCommandRef.current?.commandState) return;

    // Limpiar cualquier intervalo existente
    if (typingInterval.current) {
      clearInterval(typingInterval.current);
      typingInterval.current = null;
    }

    // Reiniciar estados relacionados
    commandIndex.current = 0;
    setInput('');
    
    const commandString = currentCommandRef.current.commandState || '';
    
    typingInterval.current = setInterval(() => {
      if (commandIndex.current < commandString.length) {
        // Usar el valor actualizado del índice directamente desde el closure
        const currentIndex = commandIndex.current;
        setInput(prev => prev + commandString.charAt(currentIndex));
        commandIndex.current++;
      } else {
        clearInterval(typingInterval.current);
        typingInterval.current = null;
        runCommand(commandString);
      }
    }, 100); // Velocidad reducida para mejor visualización
  };

  handleTyping();

  return () => {
    if (typingInterval.current) {
      clearInterval(typingInterval.current);
      typingInterval.current = null;
    }
  };
}, [currentCommand.commandState, runCommand]);

  // Scroll to bottom on output update
  useEffect(() => {
    if (outputRef.current) {
      outputRef.current.parentElement.scrollTop =
        outputRef.current.parentElement.scrollHeight;
    }
  }, [output]);

  // Event handlers
  const handleInputChange = (e) => setInput(e.target.value);

  const handleInputFocus = () => {
    if (input === t("inputTerminal")) {
      setInput("");
    }
    setShowProTip(false);
  };

  return (
    <div className="terminal" style={{ position: "relative" }}>
      {showProTip && !currentCommand.commandState && (
        <div
          className="tooltip-box"
          style={{ position: "absolute", bottom: "0", right: "0" }}
        >
          <img
            src={process.env.PUBLIC_URL + "/terminal-arrow-1.png"}
            alt="pro tip arrow"
            className="tooltip-arrow"
          />
          <div>
            <img
              src={process.env.PUBLIC_URL + "/foco-blanco-2.png"}
              alt="pro tip icon"
              className="tooltip-icon"
              style={{ height: "100px" }}
            />
            <section className="tooltip-text">{t("proTip")}</section>
          </div>
        </div>
      )}
      <div className="terminal-header">
        <div className="circle red"></div>
        <div className="circle yellow"></div>
        <div className="circle green"></div>
        <div className="terminal-header-title">~/skills</div>
      </div>
      <div className="terminal-content">
        <div ref={outputRef} className="output-container">
          {output.map((line, index) => (
            <div key={index} className="command-output">
              <div className="command-input">{line.command}</div>
              <pre
                className={`command-result ${
                  line.command ? line.command : "default"
                }`}
              >
                {typeof line.result === "object" ? (
                  <code>{JSON.stringify(line.result, null, 2)}</code>
                ) : (
                  line.result
                )}
              </pre>
            </div>
          ))}
        </div>

        <div className="input-container">
          <span className="prefix">~/skills/</span>
          <div className="input-wrapper">
            <input
              ref={inputRef}
              className="terminal-input"
              type="text"
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              onFocus={handleInputFocus}
            />
            {suggestions.length > 0 && (
              <ul className="suggestions-list">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={suggestion}
                    className={index === selectedSuggestion ? "selected" : ""}
                    onClick={() => runCommand(suggestion)}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terminal;
