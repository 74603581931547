import "./ProjectCard.css";

const ProjectCard = ({ title, subtitle, tech, link }) => {
  return (
    <div className="card">
      <div className="card-title">{title}</div>
      <div className="card-subtitle">{subtitle}</div>
      <hr className="card-divider" />
      <div className="card-footer">
        <div className="tech-container">
          {tech.map((tec, i) => {
            return <div key={title+i} className="card-tech">{tec}</div>;
          })}
        </div>
        <div className="button-container">
          <button className="card-btn">More</button>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
